export const educationData = [
    {
        id: 1,
        institution: 'SGS AMNEM School',
        course: 'High School Diploma',
        startYear: '2013',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'University of Texas at Arlington',
        course: 'BS Computer Science',
        startYear: '2018',
        endYear: '2022'
    }
]