export const experienceData = [
    {
        id: 1,
        company: 'MA Technologies',
        jobtitle: 'Software Developer',
        startYear: 'Oct 2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Mactores Inc.',
        jobtitle: 'Full-Stack Developer Intern',
        startYear: 'May 2021',
        endYear: 'Aug 2021'
    },
    {
        id: 3,
        company: 'Tag8',
        jobtitle: 'Full-Stack Developer Intern',
        startYear: 'Mar 2021',
        endYear: 'May 2021'
    },
    
]