export const socialsData = {
    github: 'https://github.com/hozefatankiwala/',
    facebook: 'https://www.facebook.com/huzzei1/',
    linkedIn: 'https://www.linkedin.com/in/hozefatankiwala',
    instagram: 'https://www.instagram.com/huzefatankiwala/',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}